
import AppMixin from '~/mixins/App'
export default {
  name: 'WorkingOnIndex',
  mixins: [AppMixin],

  data() {
    return {
      listSlideBranch: [
        {
          id: 'tiktok-shop',
          bgImgMobileWebp: '/imgs/tiktok-shop-375.webp',
          bgImgMobile: '/imgs/tiktok-shop-375.png',
          bgImgIpadWebp: '/imgs/tiktok-shop-1440.webp',
          bgImgIpad: '/imgs/tiktok-shop-1440.png',
          bgImgPCWebp: '/imgs/tiktok-shop-1920.webp',
          bgImgPC: '/imgs/tiktok-shop-1920.png',
          title: this.$t('working.tiktokShop'),
          des1: this.$t('working.tiktokShopDes1'),
          des2: this.$t('working.tiktokShopDes2'),
        },
        {
          id: 'creative-video',
          bgImgMobileWebp: '/imgs/creative-video-375.webp',
          bgImgMobile: '/imgs/creative-video-375.png',
          bgImgIpadWebp: '/imgs/creative-video-1440.webp',
          bgImgIpad: '/imgs/creative-video-1440.png',
          bgImgPCWebp: '/imgs/creative-video-1920.webp',
          bgImgPC: '/imgs/creative-video-1920.png',
          title: this.$t('working.creativeVideo'),
          des1: this.$t('working.creativeVideoDes'),
        },
        {
          id: 'strategic-partner',
          bgImgMobileWebp: '/imgs/partnert-375.webp',
          bgImgMobile: '/imgs/partnert-375.png',
          bgImgIpadWebp: '/imgs/partnert-1440.webp',
          bgImgIpad: '/imgs/partnert-1440.png',
          bgImgPCWebp: '/imgs/partnert-1920.webp',
          bgImgPC: '/imgs/partnert-1920.png',
          title: this.$t('working.partnership'),
          des1: this.$t('working.partnershipDes1'),
          des2: this.$t('working.partnershipDes2'),
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },

  methods: {
      goTo(url) {
        if (!url) {
          return
        }

        window.open(url, '_blank')
      },
    },
}
